@media screen and (min-width: 768px) {
    .custom-card {
        width: 50%;
    }
}

table tr:nth-child(2n) td {
    background-color: #f5f4f4;
    }

    